import { UpOutlined } from '@ant-design/icons';
import { Descriptions, Button, Collapse } from 'antd';
import { observer } from 'mobx-react';
import type { ReactNode } from 'react';
import React, { Component } from 'react';
import styles from '../index.less';
import Title from '../title';

const { Item } = Descriptions;
const { Panel } = Collapse;

@observer
export default class extends Component<{ store; courierQueryStore; }> {
  render(): ReactNode {
    const { buyerInfo, handleCopyReceiverInfo } = this.props.store;
    return (
      <div className={`${styles.mt12} ${styles.descriptionWrap}`}>
        <CollapseWrap
          handleCopyReceiverInfo={handleCopyReceiverInfo}
          info={(
            <div className={styles.description}>
              <Descriptions column={4}>
                <Item
                  label="收货人"
                  span={1}
                >
                  <span>
                    {buyerInfo.receiverName}
                  </span>
                </Item>
                <Item
                  label="联系方式"
                  span={1}
                >
                  <span>
                    {buyerInfo.receiverMobile}
                  </span>
                </Item>
                <Item
                  label="收货地址"
                  span={2}
                >
                  <span>
                    {buyerInfo.receiverAddress}
                  </span>
                </Item>
              </Descriptions>
            </div>
          )}
          store={this.props.store}
          text="收货信息"
          type="buyerInfo"
        />
      </div>
    );
  }
}

export const CollapseWrap = (props) => {
  const { store, info, type, text, handleCopyReceiverInfo } = props;
  return (
    <Collapse
      collapsible="disabled"
      defaultActiveKey={[type]}
      expandIcon={({ isActive }) => <UpOutlined rotate={isActive ? 0 : 180}/>}
      ghost
    >
      <Panel
        header={(
          <Title
            handleCopyReceiverInfo={handleCopyReceiverInfo}
            store={store}
            text={text}
            type={type}
          />
        )}
        key={type}
      >
        {info}
      </Panel>
    </Collapse>
  );
};
