
import { Row, Col, Typography } from 'antd';
import { toFixed } from 'egenie-common';
import { EgGrid } from 'egenie-utils';

import { observer } from 'mobx-react';
import type { ReactNode } from 'react';
import React, { Component } from 'react';
import { CollapseWrap } from '../buyerInfo';
import stylesParent from '../index.less';
import styles from './index.less';

const { Text } = Typography;
@observer
export default class extends Component<{ store ; }> {
  render(): ReactNode {
    const { orderChainsGridModel } = this.props.store;
    return (
      <div className={`${styles.mt12} ${stylesParent.descriptionWrap}`}>

        <CollapseWrap
          info={
            (
              <div style={{
                height: 422,
                marginTop: 15,
              }}
              >
                <EgGrid store={orderChainsGridModel}/>
              </div>
            )
          }
          store={this.props.store}
          text="订单全链路"
          type="orderChains"
        />
        
      </div>
    );
  }
}
