/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { CopyOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { observer } from 'mobx-react';
import type { ReactNode } from 'react';
import React from 'react';

interface Iprops {
  text: string;
  type: string;
  operation?: ReactNode;
  store?: {
    showContent: {
      orderInfo: boolean;
      buyerInfo: boolean;
    };
    toggleContentInfo: (title) => void;
  };
  handleCopyReceiverInfo?;
}

const wrapper = { display: 'flex' };

const columCe = { alignItems: 'center' };

// const flexBe = { justifyContent: 'space-between' };

const rectangular = {
  width: '3px',
  height: '14px',
  background: '#1978FF',
};
const titleStyle = {
  fontSize: '14px',
  fontWeight: 500,
  color: '#333',
  marginLeft: '7px',
};

const iconStyle = {
  marginLeft: '10px',
  color: '#C5C3CB',
  cursor: 'pointer',
  fontSize: '12px',
};

const operationStyle = { marginLeft: '4px' };
const Title = observer((props: Iprops): JSX.Element => {
  const type = props.type;
  return (
    <div style={{ ...wrapper }}>
      <div style={{
        ...wrapper,
        ...columCe,
      }}
      >
        <div style={rectangular}/>
        <div style={titleStyle}>
          <Space>
            {props.text}
            {
              type === 'buyerInfo' && (
                <CopyOutlined
                  onClick={() => {
                    props.handleCopyReceiverInfo();
                  }}
                  style={{ color: '#1978ff' }}
                />
              )
            }
          </Space>
          
        </div>
        {/* <span onClick={() => {
          props.store.toggleContentInfo(title);
        }}
        >
          {
            props.store.showContent[title] ? <UpOutlined style={iconStyle}/>
              : <DownOutlined style={iconStyle}/>
          }
        </span> */}
      </div>
      <div style={operationStyle}>
        {props.operation}
      </div>
    </div>
  );
});

export default Title;
