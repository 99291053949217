
import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import { observable, action } from 'mobx';
import api from '../../../../utils/api';

export default class Store {
  constructor(options) {
    this.parent = options.parent;
  }

  @observable public parent;

  @observable public loading = false;

  @observable public visible = false;// 订单查询显隐

  @observable public courierOrderNo: string;// 需要查询的快递单号

  @observable public courierInfo = [];

  // 打开/关闭快递查询弹窗
  @action public toggleOrderModal = (flag: boolean, mallSaleOrderId?, orderType?): void => {
    this.courierInfo = [];
    if (flag) {
      this.courierOrderNo = mallSaleOrderId;
      this.findCourierOrder(mallSaleOrderId, orderType);
    } else {
      this.loading = false;
    }
    this.visible = flag;
  };

  @action public findCourierOrder = (mallSaleOrderId, orderType) => {
    this.loading = true;
    request<BaseData<any>>({
      url: api.findCourierOrder,
      method: 'post',
      data: {
        orderId: mallSaleOrderId,
        orderType,
      },

    }).then((res) => {
      this.loading = false;
      this.courierInfo = res.data || [];
    });
  };
}

