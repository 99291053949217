
import { EgGrid } from 'egenie-utils';
import { observer } from 'mobx-react';
import type { ReactNode } from 'react';
import React, { Component } from 'react';
import { CollapseWrap } from '../buyerInfo';
import stylesParent from '../index.less';
import type Store from '../store';
import styles from './index.less';

@observer
export default class extends Component<{ store: Store; info; indx: number; orderStatus: number; }> {
  render(): ReactNode {
    const { info, indx, orderStatus } = this.props;
    return (
      <div className={`${styles.mt12} ${stylesParent.descriptionWrap}`}>
        <CollapseWrap
          info={
            (
              <div style={{
                height: '256px',
                marginTop: 15,
              }}
              >
                <EgGrid store={this.props.store[`goodsGridModel${indx}`]}/>
              </div>
            )
          }
          store={this.props.store}
          text={(
            <ProductBtn
              indx={indx}
              info={info}
              orderStatus={orderStatus}
              store={this.props.store}
            />
          )}
          type="productInfo"
        />
      </div>
    );
  }
}

const ProductBtn = observer((props) => {
  const { info, indx, orderStatus } = props;
  let detailIndex = indx;
  detailIndex++;

  return (
    <div className={styles.contentWrap}>
      <div className={styles.productBtnWrap}>
        <div>
          商品明细
          {detailIndex}
        </div>
      </div>
      {(info?.courierName || info?.courierOrderNo) && (
        <div className={styles.courierInfo}>
          <div>
            物流公司：
            {
              info.courierName
            }
          </div>
          <div style={{ marginLeft: 12 }}>
            物流单号：
            {info.courierOrderNo}
          </div>
        </div>
      )}
    </div>
  );
});
