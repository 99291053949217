import { Descriptions } from 'antd';
import { observer } from 'mobx-react';
import type { ReactNode } from 'react';
import React, { Component } from 'react';
import { CollapseWrap } from '../buyerInfo';
import styles from '../index.less';
import type Store from '../store';

const { Item } = Descriptions;
const PAY_TYPE_MAP = {
  '1': '余额',
  '2': '微信',
  '3': '支付宝',
  '4': '线下支付',
};
const orderStatusMap = {
  '0': { name: '等待买家付款（若买家未在23小时45分钟55秒内完成付款，交易自动取消）' },
  '1': { name: '买家已付款，等待卖家发货' },
  '2': { name: '卖家已发货，等待买家签收（买家如在平台规定时间内没有申请退款或确认收货，交易将自动完成）' },
  '3': { name: '交易已完成' },
  '4': { name: '交易关闭（超时未付款，交易自动关闭）' },
  '5': { name: '卖家已发货，等待买家签收（买家已申请售后）' },
};

@observer
export default class extends Component<{ store: Store; }> {
  render(): ReactNode {
    const { orderStatus, orderInfo, countDownTime } = this.props.store;
    return (
      <div
        className={`${styles.descriptionWrap}`}
        style={{ marginTop: 16 }}
      >
        <CollapseWrap
          info={(
            <div className={styles.description}>
              <Descriptions column={4}>
                <Item
                  label="订单编号"
                  span={1}
                >
                  <span>
                    {orderInfo.mallSaleOrderNo}
                  </span>
                </Item>
                <Item
                  label="订单类型"
                  span={1}
                >
                  <span>
                    {orderInfo.orderType === 1 ? '手工下单' : orderInfo.orderType === 2 ? '代发订单' : orderInfo.orderType === 3 ? '衫数样衣订单' : ''}
                  </span>
                </Item>
                {
                  orderInfo.orderType === 2 ? (
                    <Item
                      label="代发单号"
                      span={2}
                    >
                      <span>
                        {orderInfo.erpWholesaleOrderNo || '-'}
                      </span>
                    </Item>
                  ) : (
                    <Item
                      children=""
                      label=""
                      span={2}
                    />
                  )
                }
              </Descriptions>
              <Descriptions
                column={4}
              >
                <Item
                  label="下单时间"
                  span={1}
                >
                  <span>
                    {orderInfo.createdTime}
                  </span>
                </Item>
                {
                  (orderStatus === 1 || orderStatus === 2 || orderStatus === 3) && (
                    <>
                      <Item
                        label="付款时间"
                        span={1}
                      >
                        <span>
                          {orderInfo.payTime}
                        </span>
                      </Item>
                      <Item
                        label="支付方式"
                        span={2}
                      >
                        <span>
                          {orderInfo.posPayTypeName || '- -'}
                        </span>
                      </Item>
                    </>
                  )
                }
                
              </Descriptions>
            </div>
          )}
          store={this.props.store}

          // text={orderStatus === 0 ? `等待买家付款（若买家未在${countDownTime}内完成付款，交易自动取消）` : (orderStatusMap[orderStatus]?.name || '')}
          text="订单信息"
          type="orderInfo"
        />
      </div>
    );
  }
}
