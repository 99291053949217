import { Descriptions } from 'antd';
import { observer } from 'mobx-react';
import type { ReactNode } from 'react';
import React, { Component } from 'react';
import { CollapseWrap } from '../buyerInfo';
import styles from '../index.less';

const { Item } = Descriptions;
const PAY_TYPE_MAP = {
  '1': '余额',
  '2': '微信',
  '3': '支付宝',
  '4': '线下支付',
};
const orderStatusMap = {
  '0': { name: '等待买家付款（若买家未在23小时45分钟55秒内完成付款，交易自动取消）' },
  '1': { name: '买家已付款，等待卖家发货（已付款XX小时）' },
  '2': { name: '卖家已发货，等待买家签收（买家如在平台规定时间内没有申请退款或确认收货，交易将自动完成）' },
  '3': { name: '交易已完成' },
  '4': { name: '交易关闭（超时未付款，交易自动关闭）' },
  '5': { name: '卖家已发货，等待买家签收（买家已申请售后）' },
};

@observer
export default class extends Component<{ store; }> {
  render(): ReactNode {
    const { orderStatus, orderInfo } = this.props.store;
    return (
      <div
        className={`${styles.descriptionWrap}`}
        style={{ marginTop: 16 }}
      >
        <CollapseWrap
          info={(
            <div className={styles.description}>
              <Descriptions
                column={4}
              >
                <Item
                  label="备注内容"
                  span={4}
                >
                  <span>
                    {orderInfo.remark}
                  </span>
                </Item>
              </Descriptions>
            </div>
          )}
          store={this.props.store}
          text="备注信息"
          type="orderInfo"
        />
      </div>
    );
  }
}
