import type { BaseData } from 'egenie-utils';
import { EgGridModel, ImgFormatter, request } from 'egenie-utils';
import { action, extendObservable, observable } from 'mobx';
import moment from 'moment';
import React from 'react';
import noPic from '../../../../assets/images/noPic.png';
import { handleCopy } from '../../../../utils';
import api from '../../../../utils/api';
import CourierQueryStore from '../courierQuery/courierQueryStore';
import styles from './index.less';
import type { DeliverInfoVo, IOrderDetail } from './interface';
import { PriceOrNumWrap } from './priceOrNumWrap';

export default class Store {
  constructor() {
    const params = window.location.href.split('?')[1];
    const search = new URLSearchParams(params);
    const mallSaleOrderId = search.get('mallSaleOrderId');
    const posPayTypeName = search.get('posPayTypeName');
    const cloudWarehouseId = search.get('cloudWarehouseId');
    this.orderId = mallSaleOrderId;
    this.posPayTypeName = posPayTypeName === 'null' ? '' : posPayTypeName;
    this.cloudWarehouseId = cloudWarehouseId;

    this.handleOpenDetailModal(mallSaleOrderId, this.posPayTypeName);
  }

  @observable public courierQueryStore = new CourierQueryStore(this);

  @observable public visible = false;

  @observable public posPayTypeName = '';

  @observable public cloudWarehouseId = null;

  @observable public spinning = false;

  @observable public orderStatus;

  @observable public deliveryType;

  @observable public deliveryMode = 2;

  @observable public stateTime: string[];

  @observable public countDownTime = '';

  @observable public courierInfo = [];

  @observable public deliverInfoVos: DeliverInfoVo[] = [];

  @observable public orderId;

  @observable public perms: string[] = [];

  @observable public orderInfo = {
    mallSaleOrderNo: '',
    deliveryType: null,
    createdTime: '',
    payTime: '',
    buyerName: '',
    posPayTypeName: '',
    vendorName: '',
    vendorAddress: '',
    message: '',
    remark: '',
    orderType: null,
    erpWholesaleOrderNo: '',
    saleOrderSubAccountVo: {
      settleTime: '',
      data: [],
    },
  };

  @observable public buyerInfo = {
    receiverName: '',
    receiverMobile: '',
    receiverAddress: '',
    message: '',
    deliveryType: null,
    courierName: '',
    courierNo: '',
    deliverTime: '',
  };

  @observable public courierInfoVos = [];

  @observable public courierTime = {
    deliverTime: '',
    receiverTime: '',
  };

  @observable public commodityStatistics = {
    goodsAmount: 0,
    discountAmount: 0,
    orderTotalAmount: 0,
    payableAmount: 0,
    actualAmount: 0,
    postAmount: 0,
  };

  @observable public showContent = {
    orderInfo: true,
    buyerInfo: true,
    productInfo: true,
    amountInfo: true,
    galleryProductInfo: true,
  };

  // 分账信息
  public billGrid = new EgGridModel({
    columns: [
      {
        key: 'accountName',
        name: '账户名称',
      },
      {
        key: 'settleAmount',
        name: '应结金额',
        formatter: ({ row }) => {
          return (
            <PriceOrNumWrap
              type="price"
              value={row?.settleAmount}
            />
          );
        },
      },
      {
        key: 'splitAmount',
        name: '已分账金额',
        formatter: ({ row }) => {
          return (
            <PriceOrNumWrap
              type="price"
              value={row?.splitAmount}
            />
          );
        },
      },
      {
        key: 'settleTime',
        name: '最近结算时间',
      },
    ],
    rows: [],
    primaryKeyField: 'id',
    rowHeight: 36,
    showCheckBox: false,
    showPager: false,
    showReset: false,
    showNormalEmpty: true,
  });

  // 订单全链路
  @observable public orderChainsGridModel = new EgGridModel({
    columns: [
      {
        key: 'operationType',
        name: '操作类型',
      },
      {
        key: 'operationResult',
        name: '操作内容',
      },
      {
        key: 'operatorShowName',
        name: '操作人',
      },
      {
        key: 'lastUpdated',
        name: '操作时间',
        formatter: ({ row }) => {
          return (
            <div>
              {moment(row.lastUpdated).format('YYYY-MM-DD HH:mm:ss')}
            </div>
          );
        },
      },
    ],
    rows: [],
    primaryKeyField: 'id',
    forceRowClick: true,
    showCheckBox: false,
    pageSize: 10,
    showSelectedTotal: false,
    showReset: false,
  });

  @action public createGridModel = (deliverInfoVos: DeliverInfoVo[]): void => {
    if (!deliverInfoVos.length) {
      return;
    }
    this.deliverInfoVos = deliverInfoVos;
    deliverInfoVos.map((item, index) => {
      extendObservable(this, {
        [`goodsGridModel${index}`]: new EgGridModel({
          columns: [
            {
              key: 'productInfo',
              name: '商品信息',
              width: 300,
              formatter: ({ row }) => {
                return (
                  <div className={styles.productFlex}>
                    <ImgFormatter
                      height={56}
                      value={row.skuPic || noPic}
                      width={56}
                    />
                    <div className={styles.textInfo}>
                      <div
                        className={styles.firstLineText}
                        title={row?.goodsName}
                      >
                        {row.goodsName}
                      </div>
                      <div>
                        货号：
                        {row.sellerOuterNo}
                      </div>
                    </div>
                  </div>
                );
              },
            },
            {
              key: 'goodsSpec',
              name: '商品规格',
            },
            {
              key: 'detailOrderStatusName',
              name: '明细状态',
            },
            {
              key: 'sampleFeeModeDesc',
              name: '样衣费用',
            },
            {
              key: 'samplePayTypeDesc',
              name: '样衣结算',
            },
            {
              key: 'sampleReturnableDesc',
              name: '样衣是否可退',
            },
            {
              key: 'price',
              name: '单价(元)',
              width: 100,
              formatter: ({ row }) => {
                const isDiscountPrice = (row.actualPrice && row.actualPrice !== row.price);
                return (
                  <div
                    className={styles.priceWrap}
                    style={{ marginTop: isDiscountPrice ? 12 : 28 }}
                  >
                    {
                      isDiscountPrice && (
                        <div>
                          ¥
                          {row.actualPrice}
                        </div>
                      )
                    }
                    <div style={{
                      textDecoration: isDiscountPrice ? 'line-through' : 'none',
                      color: isDiscountPrice ? '#999BA4' : '#000',
                    }}
                    >
                      ¥
                      {row.price}
                    </div>
                  </div>
                );
              },
            },
            {
              key: 'originNum',
              name: '下单数量',
              width: 100,
              formatter: ({ row }) => {
                return (
                  <PriceOrNumWrap value={row.originNum}/>
                );
              },
            },
            {
              key: 'num',
              name: '实发数量',
              width: 100,
              formatter: ({ row }) => {
                return (
                  <PriceOrNumWrap value={row.num}/>
                );
              },
            },
            {
              key: 'totalPrice',
              name: '小计',
              width: 100,
              formatter: ({ row }) => {
                return (
                  <PriceOrNumWrap
                    type="price"
                    value={row.totalPrice}
                  />
                );
              },
            },
          ],
          rows: [],
          primaryKeyField: 'goodsSkuId',
          rowHeight: 72,
          showCheckBox: false,
          showPager: false,
          showReset: false,
        }),
      });
      this[`goodsGridModel${index}`].rows = item.detailByDeliverVos;
    });
  };

  @action public getPerms = (): void => {
    request<BaseData<string[]>>({
      url: `${api.getPerms}?resourceId=60050`,
      method: 'get',
    }).then((res) => {
      const array2Perms = (arr) => {
        let arrTmp = [];
        if (arr.length == 0) {
          return [];
        }
        arrTmp = arr.map((item) => item.split('_')[1]);
        return arrTmp;
      };
      const perms = array2Perms(res.data || []);
      this.perms = perms;
    });
  };

  @action public handleOpenDetailModal = async(mallSaleOrderId, posPayTypeName): Promise<void> => {
    try {
      this.orderId = mallSaleOrderId;
      await this.queryOrderDtail(mallSaleOrderId, posPayTypeName);
    } catch (e) {
      console.log(e);
    }
  };

  // 获取订单详情
  @action public queryOrderDtail = async(id, posPayTypeName) => {
    this.spinning = true;
    try {
      const url = api.queryGalleryDetail;
      const res = await request<BaseData<IOrderDetail>>({ url: `${url}/${id}` });
      this.spinning = false;

      const queryParam = this.orderChainsGridModel.queryParam;

      const { orderStatus, remark, countDownTime, deliveryType, payType, vendorName, vendorAddress,
        createdTime, payTime, mallSaleOrderNo, buyerName, cancelTime, receiverName, receiverMobile,
        receiverAddress, message, deliverTime, receiverTime, courierName, courierNo, detailPcVos,
        discountAmount, payableAmount, actualAmount, postAmount, goodsAmount, orderTotalAmount,
        deliveryMode, orderType, erpWholesaleOrderNo, saleOrderSubAccountVo, splitAcountVos, deliverInfoVos } = res.data;

      // 订单状态
      this.orderStatus = orderStatus;
      this.deliveryType = deliveryType;
      this.deliveryMode = deliveryMode;

      // 倒计时
      this.countDownTime = countDownTime;

      // 状态时间
      let stateTime = [];
      if (orderStatus === 4) {
        stateTime = [
          createdTime,
          cancelTime,
        ];
      } else {
        if (deliveryType === 1) {
          stateTime = [
            createdTime,
            payTime,
            deliverTime,
            receiverTime,
          ];
        } else {
          stateTime = [
            createdTime,
            payTime,
            deliverTime,
            receiverTime,

            // receiverTime,
          ];
        }
      }
      this.stateTime = stateTime;

      let splitAccountData = [];
      if (saleOrderSubAccountVo) {
        const obj = {
          shopNo: '平台分账',
          vendorAmount: saleOrderSubAccountVo?.platformAmount,
        };
        let vendorSubArr = saleOrderSubAccountVo?.vendorSubAccountVos || [];
        vendorSubArr = vendorSubArr.map((el) => {
          el.shopNo = `${el.shopNo}分账`;
          return el;
        });
        splitAccountData = [obj].concat(vendorSubArr);
      }

      // 分账信息
      this.billGrid.rows = splitAcountVos?.map((el, index) => {
        return {
          id: index,
          ...el,
        };
      }) || [];

      // 订单信息
      this.orderInfo = {
        mallSaleOrderNo,
        createdTime,
        buyerName,
        payTime,
        deliveryType: deliveryType === 1 ? '档口自提' : '物流配送',
        posPayTypeName,
        vendorName,
        vendorAddress,
        message,
        remark,
        orderType,
        erpWholesaleOrderNo,
        saleOrderSubAccountVo: {
          data: splitAccountData,
          settleTime: saleOrderSubAccountVo?.settleTime,
        },
      };

      // 买家信息
      this.buyerInfo = {
        receiverName,
        receiverMobile,
        receiverAddress,
        message,
        deliveryType,
        courierName,
        courierNo,
        deliverTime,
      };

      // 发货和签收时间（目前是只有一个）
      this.courierTime = {
        deliverTime,
        receiverTime,
      };

      // 商品明细
      this.createGridModel(deliverInfoVos);

      // 商品汇总信息
      this.commodityStatistics = {
        goodsAmount,
        discountAmount,
        payableAmount,
        actualAmount,
        postAmount,
        orderTotalAmount,
      };

      this.visible = true;

      const resLog = await request<BaseData<IOrderDetail>>({
        url: `${api.queryOrderLog}`,
        method: 'post',
        data: {
          ...queryParam,
          pageSize: 100,
          id,
          tableName: 'mall_sale_order',
        },
      });

      // 全链路
      this.orderChainsGridModel.rows = resLog.data.list;
      this.orderChainsGridModel.total = resLog.data.totalCount;
      if (orderStatus === 2 || orderStatus === 3) {
        this.courierInfo = [];
        const res = await request<BaseData<any>>({
          url: api.findCourierOrder,
          method: 'post',
          data: {
            orderId: id,
            orderType: 1,
          },
        });
        this.courierInfo = res.data || [];
      }
    } catch (e) {
      console.log(e);
      this.spinning = false;
    }
  };

  @action public closeDetailModal = (): void => {
    this.visible = false;
  };

  @action public handleCopyReceiverInfo = () => {
    const { receiverName, receiverMobile, receiverAddress } = this.buyerInfo;
    const content = `${receiverName},${receiverMobile},${receiverAddress}`;
    handleCopy(content);
  };
}
