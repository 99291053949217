import { Steps } from 'antd';
import { nanoid } from 'nanoid';
import type { ReactNode } from 'react';
import React, { Component } from 'react';
import styles from './index.less';

const { Step } = Steps;

interface IProps{
  selectedData: string[];
  orderStatus: number;
  countDownTime: string;
  deliveryType?: number;
  getSteps?;
  deliveryMode?;
}

export default class extends Component<IProps> {
  private stepsGroup = [
    {
      name: '提交订单',
      icon: 'icon-bzdgl',
    },
    {
      name: '付款成功',
      icon: 'icon-d_finance',
    },
    {
      name: '卖家发货',
      icon: 'icon-register_purchaser',
    },

    // {
    //   name: '确认收货',
    //   icon: 'icon-non_delivery_statistics',
    // },
    {
      name: '交易完成',
      icon: 'icon-receipt',
    },
  ];

  private closeStepsGroup = [
    {
      name: '提交订单',
      icon: 'icon-bzdgl',
    },
    {
      name: '关闭',
      icon: 'icon-close_over',
    },
  ];

  private bannerElement = () => {
    const { orderStatus, countDownTime } = this.props;
    return (
      <div className={styles.banner}>
        {
          orderStatus === 0 && (
            <>
              <div className={styles.bannerState}>
                等待买家付款
              </div>
              <div>
                （若买家未在
                {countDownTime}
                内完成付款,交易自动取消）
              </div>
            </>
          )
        }
        {
          orderStatus === 1 && (
            <div className={styles.bannerState}>
              买家已付款，等待卖家发货
            </div>
          )
        }
        {
          orderStatus === 2 && (
            <>
              <div className={styles.bannerState}>
                卖家已发货，等待买家签收
              </div>
              <div>
                (若买家在
                {countDownTime}
                内没有申请退款或确认收货,交易将自动完成)
              </div>
            </>
          )
        }
        {
          orderStatus === 3 && (
            <div className={styles.bannerState}>
              交易成功！
            </div>
          )
        }
        {
          orderStatus === 4 && (
            <>
              <div className={styles.bannerState}>
                订单关闭
              </div>
              <div>
                {countDownTime}
              </div>
            </>
          )
        }
        {
          orderStatus === 5 && (
            <div className={styles.bannerState}>
              网仓正在拿货
            </div>
          )
        }
      </div>
    );
  };

  render(): ReactNode {
    const steps = this.props.orderStatus === 4 ? this.closeStepsGroup : this.stepsGroup;
    if (this.props.orderStatus !== 4 && this.props.deliveryMode === 1) {
      steps[2].name = '云仓发货';
    }
    return (
      <>
        <div
          className={styles.stepWrapper}
          style={{ padding: this.props.orderStatus === 4 ? '32px 400px' : '32px 200px' }}
        >
          <Steps>
            {steps.map((_item, index) => {
              const { selectedData } = this.props;
              const isTime = selectedData && selectedData[index];
              return (
                <Step
                  description={isTime ? selectedData[index] : ''}
                  icon={<i className={_item.icon}/>}
                  key={nanoid()}
                  status={isTime ? 'finish' : 'wait'}
                  title={_item.name}
                />
              );
            })}
          </Steps>
        </div>
        <div className={styles.bannerContainer}>
          {this.bannerElement()}
        </div>
      </>
    );
  }
}
