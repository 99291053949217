import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import AmountInfo from './amountInfo';
import BuyerInfo from './buyerInfo/index';
import CourierInfo from './courierInfo/index';
import styles from './index.less';
import OrderChains from './orderChains';
import OrderInfo from './orderInfo/index';
import OrderStatusInfo from './orderStatusInfo/index';
import ProductInfo from './productInfo/index';
import RemarkInfo from './remarkInfo/index';
import OrderSteps from './steps/index';
import Store from './store';

const store = new Store();

const ChooseStyleOrderDetail = observer(() => {
  const { deliverInfoVos, orderStatus, countDownTime, stateTime, spinning, deliveryType, deliveryMode, courierQueryStore } = store;
  return (

    <Spin
      size="large"
      spinning={spinning}
      tip="数据加载中..."
    >
      <div className={styles.page}>
        <OrderSteps
          countDownTime={countDownTime}
          deliveryMode={deliveryMode}
          deliveryType={deliveryType}
          orderStatus={orderStatus}
          selectedData={stateTime}
        />
        <OrderStatusInfo store={store}/>
        {
          (orderStatus !== 0 && orderStatus !== 5) && (
            <RemarkInfo store={store}/>
          )
        }
        <OrderInfo store={store}/>
        <BuyerInfo
          courierQueryStore={courierQueryStore}
          store={store}
        />
        {
          (orderStatus === 2 || orderStatus === 3) && (
            <CourierInfo
              store={store}
            />
          )
        }
        {deliverInfoVos?.map((item, index) => (
          <ProductInfo
            indx={index}
            info={item}
            key={item.deliverOrderId}
            orderStatus={orderStatus}
            store={store}
          />
        ))}
        <AmountInfo store={store}/>
        <OrderChains store={store}/>
      </div>
    </Spin>
  );
});

export default ChooseStyleOrderDetail;
