import { LeftOutlined } from '@ant-design/icons';
import { Col, Row, Timeline, Drawer, Spin, Tabs } from 'antd';
import { observer } from 'mobx-react';
import { nanoid } from 'nanoid';
import React from 'react';
import type Store from './courierQueryStore';
import styles from './index.less';

const { TabPane } = Tabs;

interface Props {
  store: Store;
}

const OrderQueryModal = observer((props: Partial<Props>): JSX.Element => {
  const { visible,
    loading,
    toggleOrderModal,
    courierInfo } = props.store;
  return (
    <Drawer
      bodyStyle={{ padding: 0 }}
      className={styles.drawer}
      closeIcon={null}
      mask
      maskClosable
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}
      onClose={() => toggleOrderModal(false)}
      open={visible}
      width={400}
    >
      <div className={styles.drawerHeader}>
        <div className={styles.backContainer}>
          <LeftOutlined
            className={styles.backIcon}
            onClick={() => toggleOrderModal(false)}
          />
        </div>
        <span>
          物流信息
        </span>
      </div>
      <div
        className={styles.pl12}
        style={{ marginTop: 12 }}
      >
        此订单包含
        {courierInfo?.length || 0}
        个物流单
      </div>

      <div className={styles.drawerBody}>
        <Spin
          spinning={loading}
          tip="加载中..."
          wrapperClassName={styles.spin}
        >
          <Tabs>
            {
              courierInfo?.map((item, index) => {
                let indx = index;
                return (
                  <TabPane
                    key={item.courierOrderNo}
                    tab={`包裹${++indx}`}
                  >
                    <div className={styles.content}>
                      <div className={`${styles.lh16} ${styles.c2b} ${styles.mt12} ${styles.mb4}`}>
                        {`快递公司：${item?.courierName || ''}`}
                      </div>
                      <div className={`${styles.lh16} ${styles.c2b} ${styles.mb4}`}>
                        {`快递单号：${item?.courierOrderNo || ''}`}
                      </div>
                      <div className={`${styles.lh16} ${styles.c2b} ${styles.mb20} `}>
                        {`发货时间：${item?.deliverGoodsTime || ''}`}
                      </div>
                      <div className={styles.mb20}>
                        <div className={`${styles.lh16} ${styles.c2b} ${styles.mb4}`}>
                          {`商品信息：共${item?.totalNum || 0}件`}
                        </div>
                        <div className={styles.ml61}>
                          <Row gutter={[
                            8,
                            8,
                          ]}
                          >
                            {item?.detailVoList && item?.detailVoList.map((el) => {
                              return (
                                <Col key={el?.skuId}>
                                  <Image
                                    num={el?.num}
                                    url={el?.pic}
                                  />
                                </Col>
                              );
                            })}
                          </Row>
                        </div>
                      </div>
                      <div>
                        <div className={styles.mb12}>
                          物流轨迹
                        </div>
                        <Timeline mode="left">
                          {item?.courierDetailListVoList && item.courierDetailListVoList.map((el) => {
                            return (
                              <Timeline.Item
                                dot={<Dot color={el.status === '已收货' ? '#1978FF' : ''}/>}
                                key={nanoid()}
                                label={el.statusTime}
                              >
                                {el.trace}
                              </Timeline.Item>
                            );
                          })}
                        </Timeline>
                      </div>
                    </div>
                  </TabPane>
                );
              })
            }
          </Tabs>
          
        </Spin>
      </div>
    </Drawer>
  );
});

const Dot: React.FC<{ color?: string; }> = (props) => {
  return (
    <div
      className={styles.dot}
      style={{ backgroundColor: props.color || '#D3D3D3' }}
    />
  );
};

const Image: React.FC<{ url: string; num: number; }> = (props) => {
  const { url = '', num = '' } = props;
  return (
    <div
      className={styles.productImg}
    >
      <img
        alt=""
        src={url}
      />
      <div className={styles.goodsCount}>
        {`${num}件`}
      </div>
    </div>
  );
};

export const CourierInfoItem = observer((props) => {
  const { courierInfoItem } = props;
  return (
    <>
      <Col
        className={styles.colLabel}
        span={4}
      >
        快递公司：
      </Col>
      <Col
        className={styles.colValue}
        span={18}
      >
        {courierInfoItem?.courierName}
      </Col>
      <Col
        className={styles.colLabel}
        span={4}
      >
        快递单号：
      </Col>
      <Col
        className={styles.colValue}
        span={18}
      >
        {courierInfoItem?.courierOrderNo}
      </Col>
      <Col
        className={styles.colLabel}
        span={4}
      >
        发货时间：
      </Col>
      <Col
        className={styles.colValue}
        span={18}
      >
        {courierInfoItem?.deliverGoodsTime}
      </Col>
    </>
  );
});

export const TimeLineInfo = observer((props) => {
  const { courierInfoItem } = props;
  return (
    <Timeline mode="left">
      {
        courierInfoItem?.courierDetailListVoList && courierInfoItem.courierDetailListVoList.map((item) => {
          const { status, statusTime, trace, waybillCode } = item;
          return (
            <Timeline.Item
              key={waybillCode}
              label={statusTime}
            >
              {trace}
            </Timeline.Item>
          );
        })
      }
    </Timeline>
  );
});

export default OrderQueryModal;
