import { UpOutlined } from '@ant-design/icons';
import { Descriptions, Button, Collapse, Row, Col, Timeline, Tabs } from 'antd';
import { observer } from 'mobx-react';
import type { ReactNode } from 'react';
import React, { Component } from 'react';
import { CourierInfoItem, TimeLineInfo } from '../../courierQuery/courierQueryModal';
import Title from '../title';
import styles from './index.less';

const { Panel } = Collapse;
const { TabPane } = Tabs;

@observer
export default class extends Component<{ store; }> {
  render(): ReactNode {
    const { courierInfo } = this.props.store;
    return (
      <div className={`${styles.mt12} ${styles.descriptionWrap}`}>
        <CollapseWrap
          info={(
            <div
              className={styles.description}
              style={{
                width: 1200,
                maxHeight: 'auto',
              }}
            >
              <Tabs>
                {
                  courierInfo?.map((item, index) => {
                    let indx = index;
                    return (
                      <TabPane
                        key={item.courierOrderNo}
                        tab={`包裹${++indx}`}
                      >
                        <div className={styles.courierWrap}>
                          <div
                            className={styles.courierInfo}
                            style={{ width: '40%' }}
                          >
                            <Row>
                              <CourierInfoItem courierInfoItem={item}/>
                              <Col
                                className={styles.colLabel}
                                span={4}
                              >
                                商品信息：
                              </Col>
                              <Col
                                className={styles.colValue}
                                span={16}
                              >
                                <div className={styles.productInfoWrap}>
                                  <div>
                                    {item?.totalNum}
                                    件
                                  </div>
                                  <div className={styles.productImgWrap}>
                                    {
                                      item?.detailVoList && item?.detailVoList.map((item) => {
                                        const { skuId, num, pic } = item;
                                        return (
                                          <div
                                            className={styles.productImg}
                                            key={skuId}
                                          >
                                            <img
                                              alt=""
                                              src={pic}
                                            />
                                            <div className={styles.jianshu}>
                                              {num}
                                              件
                                            </div>
                                          </div>
                                        );
                                      })
                                    }
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <div
                            className={styles.courierInfo}
                            style={{ width: '60%' }}
                          >
                            <Row>
                              <Col
                                className={styles.colLabel}
                                span={4}
                              >
                                商品轨迹：
                              </Col>
                              <Col
                                className={styles.colLabel}
                                span={20}
                                style={{ marginTop: 20 }}
                              >
                                <TimeLineInfo courierInfoItem={item}/>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </TabPane>
                    );
                  })
                }
              </Tabs>
            </div>
          )}
          store={this.props.store}
          text="发货信息"
          type="sendInfo"
        />
      </div>
    );
  }
}

export const CollapseWrap = (props) => {
  const { store, info, type, text } = props;
  return (
    <Collapse
      defaultActiveKey={[type]}
      expandIcon={({ isActive }) => <UpOutlined rotate={isActive ? 0 : 180}/>}
      ghost
    >
      <Panel
        header={(
          <Title
            store={store}
            text={text}
            type={type}
          />
        )}
        key={type}
      >
        {info}
      </Panel>
    </Collapse>
  );
};
