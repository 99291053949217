import { Descriptions } from 'antd';
import { observer } from 'mobx-react';
import type { ReactNode } from 'react';
import React, { Component } from 'react';
import { CollapseWrap } from '../buyerInfo';
import styles from '../index.less';
import type Store from '../store';

const { Item } = Descriptions;
const PAY_TYPE_MAP = {
  '1': '余额',
  '2': '微信',
  '3': '支付宝',
  '4': '线下支付',
};

@observer
export default class extends Component<{ store: Store; }> {
  render(): ReactNode {
    const { orderInfo } = this.props.store;
    return (
      <div
        className={`${styles.descriptionWrap}`}
        style={{ marginTop: 16 }}
      >
        <CollapseWrap
          info={(
            <div className={styles.description}>
              <Descriptions
                column={4}
              >
                {/* <Item
                  label="订单编号"
                  span={1}
                >
                  <span>
                    {orderInfo.mallSaleOrderNo}
                  </span>
                </Item>
                <Item
                  label="提货方式"
                  span={1}
                >
                  <span>
                    {orderInfo.deliveryType}
                  </span>
                </Item>
                <Item
                  label="支付方式"
                  span={2}
                >
                  <span>
                    {PAY_TYPE_MAP[orderInfo.payType] || '- -'}
                  </span>
                </Item>
                <Item
                  label="档口名称"
                  span={1}
                >
                  <span>
                    {orderInfo.vendorName}
                  </span>
                </Item> */}
                <Item
                  label="买家昵称"
                  span={4}
                >
                  <span>
                    {orderInfo.buyerName}
                  </span>
                </Item>
                <Item
                  label="买家留言"
                  span={4}
                >
                  <span>
                    {orderInfo.message}
                  </span>
                </Item>
              </Descriptions>
            </div>
          )}
          store={this.props.store}
          text="买家信息"
          type="orderInfo"
        />
      </div>
    );
  }
}
