
import { Row, Col, Typography } from 'antd';
import { toFixed } from 'egenie-common';
import { observer } from 'mobx-react';
import type { ReactNode } from 'react';
import React, { Component } from 'react';
import type Store from '../store';
import styles from './index.less';

const { Text } = Typography;
@observer
export default class extends Component<{ store: Store ; }> {
  render(): ReactNode {
    const { commodityStatistics, orderStatus } = this.props.store;
    return (
      <>
        <Row
          className={styles.summary}
          justify="end"
          style={{ borderBottom: '1px solid #f0f0f0' }}
        >
          <Col span={3}>
            <div>
              <Text className={styles.span}>
                商品总金额：
              </Text>
              <Text>
                ¥
                {' '}
                {toFixed(Number(commodityStatistics?.goodsAmount || 0), 2)}
              </Text>
            </div>
            <div>
              <Text className={styles.span}>
                运费：
              </Text>
              <Text>
                ¥
                {' '}
                {toFixed(Number(commodityStatistics?.postAmount || 0), 2)}
              </Text>
            </div>
            <div>
              <Text className={styles.span}>
                订单总金额：
              </Text>
              <Text>
                ¥
                {' '}
                {toFixed(Number(commodityStatistics?.orderTotalAmount || 0), 2)}
              </Text>
            </div>
            <div>
              <Text className={styles.span}>
                优惠金额：
              </Text>
              <Text>
                ¥
                {' '}
                {toFixed(Number(commodityStatistics?.discountAmount || 0), 2)}
              </Text>
            </div>
          </Col>
        </Row>
        <Row
          className={styles.summary}
          justify="end"
        >
          <Col span={3}>
            <div>
              <Text className={styles.span}>
                实付金额：
              </Text>
              <Text>
                <span className={styles.payment}>
                  ¥
                  {' '}
                  {orderStatus === 0 ? 0 : toFixed(Number(commodityStatistics?.actualAmount || 0), 2).toLocaleString()}
                </span>
              </Text>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

