import { observer } from 'mobx-react';
import React from 'react';

export const PriceOrNumWrap = observer((props) => {
  return (
    <span>
      {
        props.type === 'price' ? '¥' : ''
      }
      {
        (props.value || props.value === 0) ? `${props.value}` : '-'
      }
    </span>
  );
});
